.Splash {
    position: relative;
    width: 566px;
    margin: 0 auto;
}
.Splash .Splash-about-p {
    line-height: 26px;
    margin-bottom: 16px;
    font-size: 21px;
}
.Splash .Splash-about-p:last-of-type {
    margin-bottom: 30px;
}


.MyName {
    font-weight: lighter;
    font-size: 55px;
    margin: 0 0 inherit 0;
    text-align: center;
}

.Tagline {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}
