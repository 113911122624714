body {
  margin: 0;
  padding: 0;
  border: 0 none;
  box-sizing: border-box;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 200;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  transition: all .4s cubic-bezier(.25,.8,.25,1);
}

html, body {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 0;

    color: rgba(0,0,0,0.87);
    background-color: rgb(250,250,250);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, .App {
  height: 100%;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
