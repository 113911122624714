.SocialBar {
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.SocialBar li {
        font-size: 25px;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.SocialBar li:hover {
    transform: scale(1.5);
    cursor: pointer;
}
